import { Col, Row, Stack } from 'react-bootstrap';
import { FaMoneyBillWave } from 'react-icons/fa';
import { SvgIcons } from '../../../components/common/SvgIcons';
import React, { FC, useEffect, useState } from 'react';
interface IBillingStatus {
    dashboardData?: any
}

const BillingStatus: FC<IBillingStatus> = ({
    dashboardData
}) => {
    const [billingData, setBillingData] = useState<any>({});
    useEffect(() => {
        const billingPpdata = dashboardData?.billingPPDetails ? dashboardData?.billingPPDetails : [];
        for (const iterator of billingPpdata) {
            setBillingData(iterator);
        }
    }, [dashboardData]);

    const statusClassName = (status: string) => {
        // const successStatuses = ['Current', 'Stage II PIF Closed', 'Stage I PIF'];
        // const dangerStatuses = ['Collections', 'Termination Stage', 'Cancelled', 'Stage II Cancelled', 'Offboard'];
        const successStatuses = ['Current', 'Stage II PIF Closed', 'Stage I PIF', 'Paid in Full', 'Waiting'];
        const dangerStatuses = ['Collections', 'Termination Stage', 'Cancelled', 'Stage II Cancelled', 'Offboard', 'On Hold', 'Past due', 'Terminated', 'Filing fee and Past Due', 'Filing fee'];
        const yellowStatuses = ['Conditions Applied'];

        if (successStatuses?.includes(status)) {
            return 'bg-success-subtle text-success';
        } else if (status === 'Not Applicable') {
            return 'bg-dark-subtle';
        } else if (status === 'Default') {
            return 'bg-warning-subtle text-warning';
        } else if (dangerStatuses.includes(status)) {
            return 'bg-danger-subtle text-danger';
        } else if (yellowStatuses.includes(status)) {
            return 'theme-yellow-light-40 text-body';
        } else {
            return 'bg-dark-subtle';
        }
    };

    const overdueClassName = (status: string) => {
        switch (status) {
            case 'N/A - Current':
                return 'bg-success-subtle text-success';
            case 'Does Not Apply':
                return 'bg-dark-subtle';
            case '>Due Date - <15':
                return 'bg-warning-subtle text-warning';
            case 'Conditions Applied':
                return 'theme-yellow-light-40 text-body';
            default:
                return 'bg-danger-subtle text-danger';
        }
    };

    return (
        <Row className="g-3 g-xl-4">
            < Col
                md={6}
                lg={4}
                className="mw-1 text-wrap"
            >
                <Stack
                    direction="horizontal"
                    gap={3}
                    className={`alert border-0 gap-3 h-100 hstack m-0 px-3 py-1 w-100 rounded-1 ${statusClassName(billingData?.billing_account_status?.value_string)}`}
                >
                    <Stack
                        direction="vertical"
                        className="me-auto text-body fw-semibold dashboard-card-box"
                    >
                        {billingData?.billing_account_status?.value_string ?
                            <h5 className="mb-0 fw-semibold">{billingData?.billing_account_status?.value_string}</h5>
                            : ''
                        }
                        <small>Billing Account Status</small>
                    </Stack>
                    <span>
                        {SvgIcons.billingIcon}
                    </span>
                </Stack>
            </Col >
            <Col
                md={6}
                lg={4}
                className="mw-1 text-wrap"
            >
                <Stack
                    direction="horizontal"
                    gap={3}
                    className={`alert border-0 gap-3 h-100 hstack m-0 px-3 py-1 w-100 rounded-1 bg-primary-subtle text-primary`}
                >
                    <Stack
                        direction="vertical"
                        className="me-auto text-body fw-semibold dashboard-card-box"
                    >
                        {billingData?.progress?.value_string ?
                            <h5 className="mb-0 fw-semibold">{billingData?.progress?.value_string}</h5>
                            : ''
                        }
                        <small>Progress of Payment</small>
                    </Stack>
                    <span>
                        {SvgIcons.dashboardPaymentIcon}
                    </span>
                </Stack>
            </Col>
            <Col
                md={6}
                lg={4}
                className="mw-1 text-wrap"
            >
                <Stack
                    direction="horizontal"
                    gap={3}
                    className={`alert border-0 gap-3 h-100 hstack m-0 px-3 py-1 w-100 rounded-1 ${overdueClassName(billingData?.overByDays)}`}
                >
                    <Stack
                        direction="vertical"
                        className="me-auto text-body fw-semibold dashboard-card-box"
                    >
                        {billingData?.overByDays ?
                            <h5 className="mb-0 fw-semibold">{billingData?.overByDays}</h5>
                            : ''
                        }
                        <small>Installment Overdue</small>
                    </Stack>
                    <span>
                        <FaMoneyBillWave size={32} />
                    </span>
                </Stack>
            </Col>
        </Row >
    )
}

export default BillingStatus